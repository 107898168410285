import React from 'react';

const Footer = () => { 
  return (
    <footer className="bg-atext-primary-content p-4">
      <div className="container mx-auto text-center">
        <p><a href="https://notreal003.github.io/support/copyright" aria-label="Copyright">&copy;</a> 2025 NotReal003. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
